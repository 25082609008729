<template>
  <div>
    <van-nav-bar
      title="升级成金牌合伙人"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="my_tips">
      1.金牌合伙人开通费用为1999元，有效期一年<br/>
      2.开通请联系客服，支付费用后30分钟内完成开通<br/>
      3.金牌合伙人权益：<br/>
      <table class="my_tb">
        <tr><th colspan="2">金牌合伙人权益</th></tr>
        <tr><td>权益1</td><td>额外奖励按最高级别计算，无月推荐数量限制</td></tr>
        <tr><td>权益2</td><td>以原价50%的价格直接购买授权码</td></tr>
      </table>
    </div>
    
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
export default {
  components: {
    CopyRight,
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>